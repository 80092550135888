import React from "react";
import { Routes, Route } from "react-router-dom";
import { ContentStyled  } from './globalStyles';

import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Cookies from "./components/Cookies";
import Companies from "./pages/Companies";
import CompanyWantToHire from "./pages/CompanyWantToHire";
import ClinicsAndHospitals from './pages/ClinicsAndHospitals';
import MyEyeCare from './pages/MyEyeCare';
import MyEyeCareWantHire from './pages/MyEyeCareWantHire';
import AboutUs from './pages/AboutUs';
import TermsAndPolicies from './pages/TermsAndPolicies';
import CookiesPolicy from './pages/CookiesPolicy';
import PrivacyPolicies from './pages/PrivacyPolicies';
import UseTerms from './pages/UseTerms';
import EyecareFinanciaTerms from './pages/EyecareFinanciaTerms';
import ConsentForm from './pages/ConsentForm';
import SuccessFormBV from './pages/SuccessFormBV';
import SuccessFormEyecareBI from './pages/SuccessFormEyecareBI';

const Routers = function() {
    return (
        <>
            <Navbar />
            <ContentStyled>
                <Routes forceRefresh={false}>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/produtos/empresas" exact element={<Companies />} />
                    <Route path="/produtos/empresas/quero-contratar" exact element={<CompanyWantToHire />} />
                    <Route path="/produtos/empresas/clinicas-e-hospitais" exact element={<ClinicsAndHospitals />} />
                    <Route path="/produtos/meu-cuidado-ocular" exact element={<MyEyeCare />} />
                    <Route path="/produtos/meu-cuidado-ocular/quero-contratar" exact element={<MyEyeCareWantHire />} />
                    <Route path="/sobre-nos" exact element={<AboutUs />} />

                    <Route path="/termos-e-politicas" exact element={<TermsAndPolicies />} />
                    <Route path="/termos-e-politicas/politica-de-cookies" exact element={<CookiesPolicy />} />

                    <Route path="/termos-e-politicas/politica-de-privacidade" exact element={<PrivacyPolicies />} />
                    <Route path="/termos-e-politicas/termos-de-uso" exact element={<UseTerms />} />
                    <Route path="/termos-e-politicas/termo-de-consentimento" exact element={<ConsentForm />} />
                    <Route path="/termos-e-politicas/eyecare-financia" exact element={<EyecareFinanciaTerms />} />

                    <Route path="/politicas-de-privacidade" exact element={<PrivacyPolicies />} />
                    <Route path="/termos-de-uso" exact element={<UseTerms />} />
                    <Route path="/termo-de-consentimento" exact element={<ConsentForm />} />

                    <Route path="/cadastro-confirmado/bv" exact element={<SuccessFormBV />} />
                    <Route path="/cadastro-confirmado/eyecarebi" exact element={<SuccessFormEyecareBI />} />
                </Routes>
            </ContentStyled>
            <Footer />
            <Cookies />
        </>
    );
}

export default Routers;