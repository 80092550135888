import styled from 'styled-components'
import { useState } from 'react'
import { useCookies } from 'react-cookie';

const CookiesContainer = styled.div`
        /* display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        position: fixed;
        z-index: 11 !important;
        
        @media (max-width: 600px) {
                align-items: center;
        } */

        .cookiesModal {
            position: fixed;
            display: flex;
            z-index: 12 !important;
            flex-direction: column;
            justify-content: flex-end;
            padding: 40px;
            width: 450px;
            border-radius: 20px;
            margin: 3%;
            background-color: var(--brand-color-extra-light-1);
            bottom: 0px;
            box-shadow: 4px 8px 8px 0px rgba(0, 0, 0, 0.16);

            @media (max-width: 600px) {
                width: 95%;
            }

            h4 {
                margin-bottom: 30px;
            }

            a {
                text-decoration: underline;
                color: var(--primary);
                cursor: pointer;
            }

            .configOptions {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin: 10px 0px;

                > div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 5px 0px;

                    label {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    }

                    > label input {
                        margin-right: 8px;
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    /* Create a custom checkbox */
                    .check {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 22px;
                        width: 22px;
                        border-radius: 4px;
                        background-color: #eee;
                        border: 2px solid var(--primary);
                    }

                    /* On mouse-over, add a grey background color */
                    label:hover input ~ .check {
                        background-color: var(--white);
                    }

                    /* When the checkbox is checked, add a blue background */
                    label input:checked ~ .check {
                        background-color: var(--primary);
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .check:after {
                        content: "";
                        display: contents;
                    }

                    .disabled {
                        background-color: var(--brand-color-light-1) !important;
                        border: none;
                        cursor: default;
                    }

                    /* Show the checkmark when checked */
                    label input:checked ~ .check:after {
                        display: flex;
                    }

                    /* Style the checkmark/indicator */
                    label .check:after {
                        width: 5px;
                        height: 10px;
                        margin-bottom: 2px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }

            .buttonContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                button {
                    font-size: 16px;
                    font-weight: 500;
                    height: 53px;
                    width: 145px;
                }
                .primary {
                        color: #fff;
                        border-radius: 50px;
                        background-color: var(--primary);
                        border: none;
                        transition: .3s;

                        :hover {
                            cursor: pointer;
                            transition: .3s;
                            background-color: var(--blue);
                        }
                    }
                .config {
                    color: var(--primary);
                    border: none;
                    background-color: rgba(0, 0, 0, 0);
                }
                
            }
        }
`

export default function Cookies(){

    const [cookies, setCookie, removeCookie] = useCookies(['accept','statistics', 'marketing']);
    const [configCookies, setConfigCookies] = useState(false)
    const [statistcs, setStatistcs] = useState(true)
    const [marketing, setMarketing] = useState(true)

    function acceptCookies(){
        setCookie('accept', true)
        statistcs === true && setCookie('statistcs', true)
        marketing === true && setCookie('marketing', true)
    }

    return(
        <>
        {
            !cookies.accept && window.location.pathname !== '/termos-e-politicas/politica-de-privacidade' && (

          
            <CookiesContainer>
                <div className='cookiesModal'>
                    <h4> Política de Cookies</h4>
                    <p>
                        Utilizamos cookies e dados de navegação para proporcionar
                        uma melhor experiência durante o uso do nosso site. 
                    
                        <a href="/termos-e-politicas/politica-de-privacidade">Consulte nossa política.</a>
                    </p>

                    {   configCookies && (
                        <div className='configOptions'>
                            <div>
                                <label htmlFor="essentials">
                                    <input type="checkbox" id="essentials" 
                                    name="essentials" checked disabled></input>
                                    <span className='check disabled'></span>
                                    Essenciais (sempre ativos)
                                </label>
                            </div>
                            <div>
                                <label htmlFor="stats">
                                    <input type="checkbox" id="stats" name="stats" 
                                    checked={statistcs} onChange={() => setStatistcs(!statistcs)}></input>
                                    <span className='check'></span>
                                    Estatísticos
                                </label>
                            </div>
                            <div>
                                <label htmlFor="marketing">
                                    <input type="checkbox" id="marketing" name="marketing" 
                                    checked={marketing} onChange={() => setMarketing(!marketing)}></input>
                                    <span className='check'></span>
                                    Marketing
                                </label>
                            </div>
                        </div>
                    )}

                    <div className='buttonContainer'>
                        <button className='primary' onClick={() => acceptCookies() }>Aceitar Todos</button>
                        <button className='config' onClick={() => setConfigCookies(!configCookies)}>{ 
                        !configCookies ? 
                        ('Configurar') : ('Fechar') 
                        }</button>
                    </div>
                </div>
            </CookiesContainer>
          )
        }
        </>
    )
}