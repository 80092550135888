import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function CookiesPolicy() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a onClick={() => ScrollTo("cookies-e-tecnologias")} title="1. Cookies e tecnologias semelhantes" alt="4. Cookies e tecnologias semelhantes"><strong>1. Cookies e tecnologias semelhantes</strong></a>
                            <li>
                                <a onClick={() => ScrollTo("cookies")} title="1.1. Cookies" alt="4.1. Cookies">1.1. Cookies</a>
                            </li>
                        </li>

                        <div className="color">
                            <span>Última atualização: 22 de fevereiro de 2021</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h1>Política de Cookies</h1>
                    <p>Prezado usuário,</p>
                    <p>Agradecemos a você pelo acesso às plataformas da Eyecare!</p>
                    <p>Antes de começar a navegar pela nossa plataforma e pelo nosso aplicativo, você deve ler e compreender nossos termos de uso, que está disponível em <a href="/termos-e-politicas/termos-de-uso" className="link-styled">Termos de uso</a> e a nossa política de privacidade, de agora em diante chamada de “Política de Privacidade”, prosseguindo somente se estiver de acordo com estes documentos.</p>
                    <p>Para nós, da Eyecare DN Soluções Digitais Ltda., sociedade empresária de responsabilidade limitada inscrita no CNPJ/ME sob o nº 35.800.459/0001-54, com sede na Alameda Ministro Rocha Azevedo, 859, apto. 31, sala 01, Cerqueira César, São Paulo, SP, CEP 01410-003 (doravante denominada “Eyecare”), proteger a sua informação privada é uma prioridade. Esta Política de Privacidade se aplica às informações e dados coletados pela Eyecare, através de todas as nossas plataformas, sejam elas o website, aplicativos de celular ou qualquer outra pela qual nossos usuários utilizem para acessar nossos serviços (doravante denominadas, indiscriminadamente, de “Plataformas”).</p>
                    <p>Esta Política de Privacidade rege nossas práticas de coleta, tratamento e uso de dados. Ela também descreve suas opções sobre o uso, o acesso e correção de seus dados pessoais. Ao acessar nossas Plataformas, você concorda com esta Política de Privacidade.</p>
                    <p>SE VOCÊ NÃO CONCORDA COM OS TERMOS DESTA POLÍTICA DE PRIVACIDADE, VOCÊ NÃO PODE USAR NEM ACESSAR AS NOSSAS PLATAFORMAS.</p>
                    <p>Atualizamos de tempos em tempos esta Política de Privacidade. Publicaremos eventuais alterações à Política de Privacidade nesta página, e incentivamos você a consultá-la periodicamente.</p>
                    <p>Se você tiver qualquer dúvida sobre esta Política de Privacidade ou o tratamento que damos aos dados que você nos fornece, por favor envie um e-mail para <a href="mailto:sistemas@eyecarehealth.com.br" title="sistemas@eyecarehealth.com.br" alt="sistemas@eyecarehealth.com.br" rel="noreferrer" target="_blank" className="link-styled">sistemas@eyecarehealth.com.br.</a></p>
                    
                    <div id="dados-que-coletamos">
                        <h2>1. Dados que coletamos</h2>
                        <p>AO UTILIZAR AS PLATAFORMAS EYECARE, VOCÊ CONCORDA QUE A EYECARE PODERÁ COMPARTILHAR SEUS DADOS COM PROFISSIONAIS E/OU INSTITUIÇÕES DA ÁREA DE SAÚDE COMO ENFERMEIROS, MÉDICOS, CLÍNICAS E HOSPITAIS, PARA QUE ESTES ENTREM, EVENTUALMENTE, EM CONTATO COM VOCÊ PARA FORNECER INFORMAÇÕES DE ASSISTÊNCIA À SAÚDE RELACIONADOS À PLATAFORMA DE SERVIÇOS, PARA OFERECER OUTROS SERVIÇOS OU PARA REALIZAR PESQUISAS. A Eyecare pode acompanhar os sites e páginas que você visita e outros aplicativos que você utiliza, a fim de determinar quais serviços de saúde são os mais populares. Poderá também monitorar seu nível de utilização do telefone. Esta informação é usada para mostrar conteúdo dentro das nossas Plataformas para clientes cujo comportamento indique interesse por áreas específicas.</p>
                        <h2 id="cookies-e-tecnologias">1. Cookies e tecnologias semelhantes</h2>
                        <h3 id="cookies">1.1. Cookies</h3>
                        <p>A Eyecare e seus parceiros usam cookies ou tecnologias semelhantes (como web beacons) para analisar tendências, administrar a Plataforma, rastrear os movimentos dos usuários no site e reunir informações demográficas sobre nossa base de usuários como um todo.</p>
                    </div>
                </div>
            </div>
        </TermsStyled>
    );
}