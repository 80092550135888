import { TermsContainer } from "./styles"
import MainContainer from "../../components/MainContainer";

export default function TermsAndPolices() {
    return (
        <TermsContainer>
            <MainContainer title="Termos e políticas" />
            <div className="container-text">
                <div>
                    <p>Consulte as versões mais recentes das políticas da Eyecare Health e os
                        principais documentos relacionados aos nossos produtos e serviços. Podemos atualizar periodicamente nossas políticas, lembre-se de consultar sempre que você precisar.</p>
                </div>
                <div className="div-links">
                    <div>
                        <a href="/termos-e-politicas/termo-de-consentimento">Termo de consentimento</a>
                    </div>
                    <div>
                        <a href="/termos-e-politicas/termos-de-uso">Termo de Uso</a>
                    </div>
                    <div>
                        <a href="/termos-e-politicas/politica-de-privacidade">Política de Privacidade</a>
                    </div>
                    <div>
                        <a href="/termos-e-politicas/politica-de-cookies">Política de Cookies</a>
                    </div>
                    <div>
                        <a href="/termos-e-politicas/eyecare-financia">Eyecare Financia</a>
                    </div>
                </div>
            </div>
        </TermsContainer>
    );
}